<template>
  <b-modal
    id="booststrapmodal"
    :title="T('Web.InvoicesPage.SendFeedbackModal.Title', 'Send feedback')"
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
    size="sm"
  >
    <div>
      <b-form-group :label="T('Web.InvoicesPage.SendFeedbackModal.Message', 'Message')" label-size="sm">
        <b-form-textarea size="sm" v-model="message" />
      </b-form-group>
      <b-form-group :label="T('Web.InvoicesPage.SendFeedbackModal.FileName', 'File name')" label-size="sm">
        <b-form-input
          size="sm"
          disabled
          v-model="invoiceData.importInfo.importFileName"
        />
      </b-form-group>
      <b-form-group :label="T('Web.InvoicesPage.SendFeedbackModal', 'Error message')" label-size="sm">
        <b-form-textarea
          size="sm"
          disabled
          v-model="invoiceData.importInfo.failedReason"
        />
      </b-form-group>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="submitting"
          @click="handleSubmit"
        >
          {{ submitting ? T("Web.Generic.Sending", "Sending") : T("Web.InvoicesPage.SendFeedbackModal.Send", "Send feedback") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleCancel"
        >
          {{ T("Web.Generic.Cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";

import InvoicesService from "@/services/invoices.service";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BRow,
    BCol,
    BButton,
  },

  created() {
    this.services = new InvoicesService();
  },
  data() {
    return {
      visible: false,
      submitting: false,
      errorMessage: "",
      message: "",
      invoiceData: {
        importInfo: {},
      },
      services: null,
    };
  },
  methods: {
    ...mapActions({
      updateInvoicesOnList: "invoiceImports/updateInvoicesOnList",
    }),
    open(invoice) {
      this.invoiceData = invoice;
      this.visible = true;
    },
    handleModalHidden() {
      this.visible = false;
    },
    handleCancel() {
      this.handleModalHidden();
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        await this.services.sendFailedImportFeedback(this.invoiceData.id, {
          body: this.message,
        });
        this.updateInvoicesOnList([this.invoiceData]);
        this.handleModalHidden();
      } finally {
        this.submitting = false;
      }
    },
  },
  computed: {},
};
</script>