<template>
  <b-row>
    <invoice-uppload-bar ref="invoiceUploadBar" />
    <b-col cols="12" class="m-auto">
      <b-tabs content-class="mt-3" @input="handleTabChanged">
        <b-tab
          :title="T('Web.InvoicesPage.ActiveInvoices', 'Active invoices')"
          active
        >
          <b-card no-body>
            <div class="header p-2">
              <b-row>
                <b-col cols="6">
                  <b-card-title>{{
                    T("Web.InvoicesPage.ActiveInvoices", "Active invoices")
                  }}</b-card-title>
                </b-col>
                <b-col cols="6">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <b-input-group>
                      <b-form-input
                        :placeholder="T('Web.Generic.Search')"
                        v-model="searchTerm"
                      />
                      <b-input-group-append
                        class="d-flex align-items-center px-1 input-append"
                        v-if="searchTerm != ''"
                        @click="searchTerm = ''"
                      >
                        <feather-icon icon="XIcon" />
                      </b-input-group-append>
                    </b-input-group>

                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="$refs.invoiceUploadBar.open()"
                      style="width: 120px"
                      class="ml-1"
                    >
                      {{ T("Web.Generic.Upload") }}
                      <feather-icon icon="UploadIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div>
              <transition name="fade" mode="out-in">
                <div
                  class="d-flex justify-content-center mt-2 mb-2"
                  v-if="isLoadingInvoiceImports && !silentLoading"
                >
                  <b-spinner />
                </div>
                <invoices-list-table
                  v-else
                  :items="importListFiltered"
                  :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
                  :pr-page="15"
                  :curren-page="currentImportPage"
                  @refresh-invoice-list="refreshImportList"
                />
              </transition>
              <div class="d-flex justify-content-center">
                <b-pagination
                  v-model="currentImportPage"
                  :total-rows="importListFiltered.length"
                  :per-page="15"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </b-card>
        </b-tab>
        <b-tab
          :title="T('Web.InvoicesPage.AwaitingApproval', 'Awaiting approval')"
        >
          <b-card no-body>
            <div class="header p-2">
              <b-row>
                <b-col cols="12" md="6">
                  <b-card-title>{{
                    T("Web.InvoicesPage.AwaitingApproval", "Awaiting approval")
                  }}</b-card-title>
                </b-col>
              </b-row>
            </div>
            <div>
              <transition name="fade" mode="out-in">
                <invoices-list-table :items="awaitingApproval" />
              </transition>
            </div>
          </b-card>
        </b-tab>
        <b-tab :title="T('Web.InvoicesPage.LockedInvoices', 'LockedInvoices')">
          <b-card no-body>
            <div class="header pt-2 pl-2 pr-2">
              <b-row>
                <b-col cols="12" md="12">
                  <b-card-title>{{
                    T("Web.InvoicesPage.LockedInvoices", "LockedInvoices")
                  }}</b-card-title>
                </b-col>
                <b-col cols="12" md="6">
                  <small>{{ T("Web.Generic.Search") }}</small>
                  <b-form-input
                    :placeholder="
                      T(
                        'Web.InvoicesPage.SerachInvoicesBy',
                        'Search by invoice number, seller, buyer or location'
                      )
                    "
                    @input="handleSearchInput"
                    v-model="closedInvoicesSearchTerm"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <b-row>
                    <b-col cols="12" md="6">
                      <small>{{ T("Web.Generic.PeriodFrom") }}</small>
                      <b-form-datepicker
                        v-model="fromDateFilter"
                        class="mb-2"
                        reset-button
                      ></b-form-datepicker>
                    </b-col>
                    <b-col cols="12" md="6">
                      <small>{{ T("Web.Generic.PeriodTo") }}</small>
                      <b-form-datepicker
                        v-model="toDateFilter"
                        class="mb-2"
                        reset-button
                      ></b-form-datepicker>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div>
              <transition name="fade" mode="out-in">
                <div
                  class="d-flex justify-content-center mt-2 mb-2"
                  v-if="isLoadingClosedInvoices"
                >
                  <b-spinner />
                </div>
                <invoices-list-table v-else :items="closedInvoices" />
              </transition>
              <div class="d-flex justify-content-center">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalClosedInvoicesCount"
                  :per-page="50"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BButton,
  BSpinner,
  BTable,
  BLink,
  BPagination,
  BFormDatepicker,
  BContainer,
  BTabs,
  BTab,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import InvoiceUpploadBar from "./components/InvoiceUpploadBar.vue";
import InvoicesListTable from "./components/InvoicesListTable.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BSpinner,
    BTable,
    BLink,
    BPagination,
    BFormDatepicker,
    BContainer,
    InvoiceUpploadBar,
    InvoicesListTable,
    BTabs,
    BTab,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    FeatherIcon,
  },
  async created() {
    this.fetchInvoiceImports();
  },
  data() {
    return {
      currentPage: 1,
      currentImportPage: 1,
      fromDateFilter: "",
      toDateFilter: "",
      awaitingApproval: [],
      typingTimer: null,
      searchTerm: "",
      silentLoading: false,
      searchTimeout: null,
      closedInvoicesSearchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      isLoadingClosedInvoices: "invoices/isLoading",
      closedInvoices: "invoices/list",
      totalClosedInvoicesCount: "invoices/totalItemsCount",

      isLoadingInvoiceImports: "invoiceImports/isLoading",
      invoicesImports: "invoiceImports/list",
    }),

    importList() {
      return this.invoicesImports;
    },

    closedInvoicesParams() {
      return {
        page: this.currentPage,
        includeOnlyWithState: "Closed",
        includeIssuedFrom: this.fromDateFilter,
        includeIssuedTo: this.toDateFilter,
        searchTerm: this.closedInvoicesSearchTerm,
      };
    },
    openInvoices() {
      return this.closedInvoices.filter((i) => i.status != "Reconciled");
    },
    lockedInvoices() {
      return this.closedInvoices.filter((i) => i.status == "Reconciled");
    },
    importListFiltered() {
      let result = this.invoicesImports;
      if (this.searchTerm != "") {
        result = result
          .filter((i) => i.invoiceNumber != null)
          .filter(
            (i) =>
              i.invoiceNumber
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase()) ||
              i.buyerName
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase()) ||
              i.sellerName.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      fetchClosedInvoices: "invoices/fetchList",
      fetchInvoiceImports: "invoiceImports/fetchList",
      feetchInvoicesAwaitingApproval:
        "economicsInvoices/fetchAwaitingApprovalList",
    }),
    handleSearchInput(term) {
      clearTimeout(this.searchTimeout);
      const self = this;
      this.searchTimeout = setTimeout(() => {
        self.fetchClosedInvoices({ params: this.closedInvoicesParams });
      }, 500);
    },
    async refreshImportList() {
      this.silentLoading = true;
      await this.fetchInvoiceImports();
    },
    async handleTabChanged(tabIndex) {
      if (tabIndex == 1) {
        this.awaitingApproval = (
          await this.feetchInvoicesAwaitingApproval()
        ).data.data;
      } else if (tabIndex == 2) {
        this.fetchClosedInvoices({ params: this.closedInvoicesParams });
      }
    },
  },
  watch: {
    currentPage() {
      this.fetchClosedInvoices({ params: this.closedInvoicesParams });
    },
    fromDateFilter(newValue, oldValue) {
      if (newValue == oldValue) return;
      if (newValue == "") {
        this.toDateFilter = "";
        this.fetchClosedInvoices({ params: this.closedInvoicesParams });
      } else {
        var date = new Date(newValue);
        var newDate = new Date(date.setMonth(date.getMonth() + 1));
        this.toDateFilter = this.format_date(newDate, "YYYY-MM-DD");
      }
    },
    toDateFilter(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.currentPage = 1;
      this.fetchClosedInvoices({ params: this.closedInvoicesParams });
    },
  },
};
</script>

<style scoped>
.input-append {
  background: #f3f2f7;
  border: 1px solid #d8d6de;
}
.input-append:hover {
  cursor: pointer;
  font-weight: bold;
}
</style>