<template>
  <div>
    <create-manual-invoice-modal ref="createManualInvoiceModal" />
    <b-sidebar
      id="sidebar-invoice-upload"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => (visible = val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ T("Web.InvoicesPage.UploadBar.Header", "Upload invoice") }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- End Header -->
        <!-- Body -->
        <b-form class="p-2" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="T('Web.InvoicesPage.UploadBar.Files', 'Files')"
            label-for="account-number"
          >
            <b-form-file
              :placeholder="
                T('Web.InvoicesPage.UploadBar.Choosefile', 'Choose a file')
              "
              no-drop
              :browse-text="T('Web.Generic.Choose')"
              selected-format=".pdf"
              v-model="files"
              :multiple="true"
              accept=".pdf, .xml"
            />
          </b-form-group>
          <div class="d-flex mt-2 align-items-center">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="files == null || submitting"
            >
              {{
                submitting
                  ? T("Web.Generic.Uploading", "Uploading..")
                  : T("Web.Generic.Uploading", "Upload")
              }}
            </b-button>
            <span class="mr-1"> {{ T("Web.Generic.Or", "or") }} </span>
            <a href="#" @click.prevent="handleEnterManuallyClicked">{{
              T("Web.InvoicesPage.UploadBar.EnterManually", "Enter manually")
            }}</a>
          </div>
          <div>
            <br />
            <br />
            <br />
            <p>
              {{
                T(
                  "Web.InvoicePage.UploadBard.SendMailText",
                  "You can also use the e-mail address below"
                )
              }}
            </p>
            <b-form-input
              id="InboundEmailInput"
              size="sm"
              :readonly="true"
              :value="`${account.id}@inbound.mail.affald.online`"
            />
            <b-button
              class="mt-1"
              size="sm"
              variant="outline-secondary"
              @click="copyEmailValue()"
              >{{ T("Web.Generic.Copy", "Copy") }}
              <feather-icon icon="CopyIcon"
            /></b-button>
          </div>
        </b-form>
        <!-- End Body -->
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import CreateManualInvoiceModal from "./CreateManualInvoiceModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BFormFile,
    CreateManualInvoiceModal,
  },
  data() {
    return {
      visible: false,
      files: null,
      submitting: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({ account: "appUser/getCurrentAccountScope" }),
  },
  methods: {
    ...mapActions({
      uploadInvoiceImportFiles: "invoiceImports/uploadInvoiceImportFiles",
    }),
    async open() {
      this.visible = true;
    },
    copyEmailValue() {
      var inputField = document.getElementById("InboundEmailInput");
console.log(   inputField.select())
      // Select the text in the input field
      inputField.select();

      // Copy the selected text
      document.execCommand("copy");

      // Deselect the input field
      inputField.setSelectionRange(0, 0);
    },
    clearForm() {
      this.files = null;
    },

    async handleSubmit() {
      try {
        this.submitting = true;
        await this.uploadInvoiceImportFiles(this.files);
        this.$emit("submitted", true);
        this.visible = false;
      } finally {
        this.submitting = false;
      }
    },

    handleEnterManuallyClicked() {
      this.$refs.createManualInvoiceModal.open();
    },
  },
};
</script>