<template>
  <b-modal
    id="booststrapmodal"
    :title="
      T('Web.InvoicesPage.ManuallyCreatePopup.Title', 'Create new invoice')
    "
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
    size="xl"
    scrollable
  >
    <!-- Invoice Head -->
    <b-row>
      <b-col cols="3">
        <b-form-group
          :label="T('Web.Generic.Invoices.InvoiceNumber')"
          label-size="sm"
        >
          <b-form-input size="sm" v-model="invoiceData.invoiceNumber" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          :label="T('Web.Generic.Invoices.IssueDate')"
          label-size="sm"
        >
          <b-form-datepicker size="sm" v-model="invoiceData.issueDate" />
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col md="3">
        <h6 class="text-muted">{{ T("Web.Generic.Invoices.Reciver") }}</h6>
        <b-form-group :label="T('Web.Generic.Name', 'Name')" label-size="sm">
          <b-form-input size="sm" v-model="invoiceData.customer.name" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Cvr')" label-size="sm">
          <b-form-input size="sm" v-model="invoiceData.customer.cvr" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Address')" label-size="sm">
          <b-form-input size="sm" v-model="invoiceData.customer.address" />
        </b-form-group>
        <b-row>
          <b-col cols="4">
            <b-form-group :label="T('Web.Generic.Zip')" label-size="sm">
              <b-form-input size="sm" v-model="invoiceData.customer.zip" />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group :label="T('Web.Generic.City')" label-size="sm">
              <b-form-input size="sm" v-model="invoiceData.customer.city" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3" offset="1">
        <h6 class="text-muted">{{ T("Web.Generic.Invoices.Sender") }}</h6>
        <b-form-group :label="T('Web.Generic.Name', 'Name')" label-size="sm">
          <b-form-input size="sm" v-model="invoiceData.supplier.name" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Cvr')" label-size="sm">
          <b-form-input size="sm" v-model="invoiceData.supplier.cvr" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Address')" label-size="sm">
          <b-form-input size="sm" v-model="invoiceData.supplier.address" />
        </b-form-group>
        <b-row>
          <b-col cols="4">
            <b-form-group :label="T('Web.Generic.Zip')" label-size="sm">
              <b-form-input size="sm" v-model="invoiceData.supplier.zip" />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group :label="T('Web.Generic.City')" label-size="sm">
              <b-form-input size="sm" v-model="invoiceData.supplier.city" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <!-- End Invoice Head  -->
    <!-- Invoice Lines -->
    <div>
      <div
        v-for="(line, index) in invoiceData.invoiceLines"
        :key="line.id"
        class="d-flex justify-content-between align-items-center"
      >
        <b-form-group label="Id" label-size="sm">
          <b-form-input size="sm" v-model="line.lineId" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Invoices.LineDate')" label-size="sm">
          <b-form-datepicker size="sm" v-model="line.date" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Invoices.LineSku')" label-size="sm">
          <b-form-input size="sm" v-model="line.sku" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Invoices.LineText', 'Text')" label-size="sm">
          <b-form-input size="sm" v-model="line.text" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Invoices.LineAmount')" label-size="sm">
          <b-form-input size="sm" type="number" v-model="line.amount" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Invoices.LineUnit')" label-size="sm">
          <b-form-select
            size="sm"
            :options="unitTypeOptions"
            v-model="line.unit"
          />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Invoices.LineUnitPrice')" label-size="sm">
          <b-form-input size="sm" type="number" v-model="line.priceAmount" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Invoices.LineTotal')" label-size="sm">
          <b-form-input size="sm" type="number" v-model="line.priceTotal" />
        </b-form-group>
        <b-button variant="flat-primary" @click="removeLineAtIndex(index)"
          >x</b-button
        >
      </div>
      <a href="#" @click="invoiceData.invoiceLines.push(emptyLine())">
        {{ T("Web.InvoicesPage.ManuallyCreatePopup.AddLine", "Add line") }}</a
      >
    </div>
    <!-- End Invoice Lines  -->
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!canSubmit || submitting"
          @click="handleSubmit"
        >
          {{ submitting ? T("Web.Generic.Creating", "Creating..") : T("Web.Generic.Create", "Create") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleCancel"
        >
          {{ T("Web.Generic.Cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { uuid } from "vue-uuid";
import { mapActions } from "vuex";

export default {
  components: {
    BFormDatepicker,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      visible: false,
      submitting: false,
      invoiceData: {
        invoiceNumber: "",
        issueDate: "",
        customer: {
          name: "",
          cvr: "",
          address: "",
          zip: "",
          city: "",
        },
        supplier: {
          name: "",
          cvr: "",
          address: "",
          zip: "",
          city: "",
        },
        invoiceLines: [this.emptyLine()],
      },
      unitTypeOptions: [
        {
          value: "PCS",
          text: this.T("Web.Generic.Units.Pcs"),
        },
        {
          value: "Tonnage",
          text: this.T("Web.Generic.Units.Tonnage"),
        },
        {
          value: "KG",
          text: this.T("Web.Generic.Units.Kg"),
        },
        {
          value: "Hours",
          text: this.T("Web.Generic.Units.Hours"),
        },
      ],
    };
  },
  methods: {
    ...mapActions({ create: "invoiceImports/createInvoiceImportTaskManually" }),
    open() {
      this.visible = true;
    },
    handleModalHidden() {
      this.visible = false;
    },
    emptyLine() {
      return {
        id: uuid.v4(),
        lineId: "",
        sku: "",
        date: "",
        text: "",
        description: "",
        note: "",
        amount: "",
        unit: "",
        priceAmount: "",
        priceTotal: "",
      };
    },
    removeLineAtIndex(index) {
      this.invoiceData.invoiceLines.splice(index, 1);
    },
    handleCancel() {
      this.handleModalHidden();
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        await this.create({ invoiceData: this.invoiceData });
        this.handleModalHidden();
      } catch (error) {
        alert(error);
        alert("Error!");
      } finally {
        this.submitting = false;
      }
    },
  },
  computed: {
    canSubmit() {
      const customer = this.invoiceData.customer;
      const supplier = this.invoiceData.supplier;

      return (
        this.invoiceData.invoiceNumber != "" &&
        this.invoiceData.issueDate != "" &&
        customer.name != "" &&
        customer.cvr != "" &&
        customer.address != "" &&
        customer.zip != "" &&
        customer.city != "" &&
        supplier.name != "" &&
        supplier.cvr != "" &&
        supplier.address != "" &&
        supplier.zip != "" &&
        supplier.city != "" &&
        this.allLinesAreValid
      );
    },
    allLinesAreValid() {
      return (
        this.invoiceData.invoiceLines.length > 0 &&
        this.invoiceData.invoiceLines.every(
          (l) =>
            l.lineId != "" &&
            l.sku != "" &&
            l.text != "" &&
            l.amount != "" &&
            l.unit != "" &&
            l.priceAmount != "" &&
            l.priceTotal != ""
        )
      );
    },
  },
};
</script>