<template>
  <b-modal
    id="booststrapmodal"
    :visible="visible"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    @hidden="handleModalHidden"
    :body-class="'p-0'"
    :title="modalTitle"
    :hide-footer="true"
    size="lg"
    :sort-by="'postingCategory'"
    :sort-desc="'asc'"
  >
    <page-loader :loading="loading">
      <div v-if="data != null">
        <b-table :items="data" :fields="tabelFields" small>
          <template #cell(date)="data">{{ format_date(data.value) }} </template>
        </b-table>
        <div class="total-table">
          <div class="header">
            <div></div>
            <div>
              <small class="font-weight-bold">{{
                T("Web.Generic.Invoices.Quantity")
              }}</small>
            </div>
            <div>
              <small class="font-weight-bold">{{
                T("Web.Generic.Invoices.LineTotal")
              }}</small>
            </div>
          </div>
          <div
            v-for="(postings, category) in postingGroupedByCategory"
            :key="category"
          >
            <div>{{ category }}</div>
            <div>{{ getTotalQtyFromPostings(postings) | toCurrency }}</div>
            <div>{{ getTotalFromPostings(postings) | toCurrency }}</div>
          </div>
        </div>
      </div>
    </page-loader>
    <div class="modal-footer">
      <b-button @click="handleModalHidden">{{ T("Web.Generic.Close") }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BTable, BButton } from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ResourceService from "@/services/base/resource.service";
export default {
  components: {
    BModal,
    BTable,
    PageLoader,
    BButton,
  },
  data() {
    return {
      modalTitle: "",
      visible: false,
      data: null,
      loading: false,
      tabelFields: [
        {
          key: "date",
          label: this.T("Web.Generic.Date", "Date"),
          sortable: true,
        },
        {
          key: "postingCategory",
          label: this.T("Web.Generic.Category"),
          sortable: true,
        },
        {
          key: "infocardName",
          label: this.T("Web.Generic.Infocards.InfocardName"),
          sortable: true,
        },
        {
          key: "internalGroupId",
          label: this.T("Web.Generic.Infocards.InternalGroupId", "Internal group id"),
          sortable: true,
        },
        {
          key: "text",
          label: this.T("Web.Generic.Postings.Text", "Text"),
          sortable: true,
        },
        {
          key: "quantity",
          label: this.T("Web.Generic.Invoices.Quantity"),
          sortable: true,
        },
        {
          key: "unitPrice",
          label: this.T("Web.Generic.Invoices.LineUnitPrice"),
          sortable: true,
        },
        {
          key: "lineTotal",
          label: this.T("Web.Generic.Invoices.LineTotal"),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    postingGroupedByCategory() {
      let list = this.data.reduce((prev, curr) => {
        if (curr.postingCategory == undefined) return prev;
        if (prev[curr.postingCategory] == undefined) {
          prev[curr.postingCategory] = [];
        }
        prev[curr.postingCategory].push(curr);
        return prev;
      }, {});
      return list;
    },
  },
  methods: {
    getTotalFromPostings(postings) {
      return postings.reduce(
        (prev, curr) => (prev = prev + curr.lineTotalRaw),
        0
      );
    },
    getTotalQtyFromPostings(postings) {
      return postings.reduce(
        (prev, curr) => (prev = prev + curr.quantityRaw),
        0
      );
    },
    async open(invoiceId) {
      this.visible = true;
      this.loading = true;
      const service = new ResourceService("economics");
      let invoice = (await service.get(`invoices/${invoiceId}`)).data.data;
      let reconcilation = (await service.get(`reconciliations/${invoiceId}`))
        .data.data;

      let infocards = (
        await new ResourceService("background-data").get("infocards")
      ).data.data;

      this.data = [];

      reconcilation.postings.forEach((element) => {
        if (element.type != "EmptyPosting") {
          let infocard = infocards.find(
            (i) => i.id == element.postingDestinationInfoCardId
          );

          this.data.push({
            date: element.postingDate,
            postingCategory: this.T("Web.Generic.PostingCategories." + element.postingCategory),
            infocardName: infocard ? infocard.name : "-",
            internalGroupId: infocard ? infocard.internalGroupId : "-",
            text: element.postingDescription,
            quantity: `${this.$options.filters.toCurrency(
              element.postingQuantity
            )} ${this.T("Web.Generic.Units." + element.postingUnit)}`,
            quantityRaw: element.postingQuantity,
            unitPrice: this.$options.filters.toCurrency(
              element.postingUnitPrice
            ),
            lineTotal: this.$options.filters.toCurrency(
              element.postingTotalPrice
            ),
            lineTotalRaw: element.postingTotalPrice,
          });
        }
      });
      this.modalTitle = `Postinger #${invoice.invoiceNumber}`;
      this.loading = false;
    },
    handleModalHidden() {
      this.visible = false;
      this.data = null;
      this.modalTitle = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.total-table {
  margin: 10px;
  border: 2px solid #ebe9f1;
  width: 50%;
  > div {
    display: flex;
    justify-content: space-between;

    > div {
      width: 50%;
      padding: 5px;
      &:first-child {
        background-color: #f3f2f7;
        color: #6e6b7b;
        font-weight: bold;
      }
      &:not(:first-child) {
        text-align: right;
      }
    }
    &.header {
      background-color: #f3f2f7;
      > div {
        padding: 0 5px;
      }
    }
  }
}

::v-deep td {
  font-size: 12px !important;
}
</style>